<template>
  <div>
    <default-loader v-if="projectFilesLoading"></default-loader>
    <div v-else>
      <div
        v-if="largeFileWarning"
        class="vd-margin-bottom-10 vd-padding-left-medium vd-padding-top-small vd-padding-bottom-small vd-display-block vd-border-radius-6 vd-background-light-yellow vd-red"
      >
        Please don't close this window, some files are quite large and may take
        a little longer to process.
      </div>
      <div
        :class="[
          'vd-border-lighter-grey vd-border-radius-6 vd-padding-20 vd-grey vd-text-align-center vd-margin-bottom-20',
          projectFiles.data.length <= 0 || isMobileScreen
            ? ''
            : 'vms-project-files__content--wrapper-fixed',
        ]"
      >
        <div
          class="vms-uploader__wrapper vd-border-lighter-grey vd-border-radius-6 vd-padding-top-small vd-padding-bottom-small"
        >
          <file-upload-component
            :api-url="apiUrl"
            @handle:process-file="handleProcessFile"
            @handle:add-file="handleAddFile"
            @handle:update-files="handleUpdateFiles"
          >
          </file-upload-component>
        </div>
      </div>
      <div
        v-if="projectFiles.data.length <= 0"
        class="vms-project-files__table--empty vd-text-align-center vd-margin-bottom-extra-large"
      >
        <img src="/images/empty-states/empty-folder.svg" />
        <div class="vd-black">
          There are no items here! Click upload above to attach files to this
          Project.
        </div>
      </div>

      <uploaded-files-list-mobile
        v-else-if="isMobileScreen"
        :files-data="projectFiles.data"
      >
        <template v-slot:uploaded-files-options="slotProps">
          <project-files-dropdown
            :file="slotProps.file"
            :is-view-option-visible="!isMobileScreen"
          ></project-files-dropdown>
        </template>
      </uploaded-files-list-mobile>

      <uploaded-files-list v-else :files-data="projectFiles.data">
        <template v-slot:uploaded-files-options="slotProps">
          <project-files-dropdown
            :file="slotProps.file"
          ></project-files-dropdown>
        </template>
      </uploaded-files-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, isNull } from 'lodash'
import UploadedFilesList from '@views/project/uploaded-files/components/uploaded-files-list'
import UploadedFilesListMobile from '@views/project/uploaded-files/components/uploaded-files-list-mobile'
import ProjectFilesDropdown from '@views/project/uploaded-files/project-files/project-files-dropdown'
import DefaultLoader from '@components/loaders/default-loader'
import FileUploadComponent from '@components/file-upload/file-upload'
import AppConfig from '@configs/app-config'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    UploadedFilesList,
    DefaultLoader,
    ProjectFilesDropdown,
    FileUploadComponent,
    UploadedFilesListMobile,
  },

  data() {
    return {
      incompleteUploadWarning: false,
      largeFileWarning: false,
      maxFileSizeWarning: 100000000,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      projectFiles: 'project/attachment/projectFiles',
      projectFilesLoading: 'project/attachment/projectFilesLoading',
      authToken: 'auth/accessToken',
      isMobileScreen: 'common/isMobileScreen',
    }),

    apiUrl() {
      return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${this.project.id}/attachments`
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.incompleteUploadWarning) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> Your files are still uploading. <br> Leaving this page will cancel all pending file uploads. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          next()
        }
      })
    } else {
      next()
    }
  },

  methods: {
    ...mapActions({
      getProjectFilesList: 'project/attachment/getProjectFilesList',
    }),

    handleProcessFile() {
      this.getProjectFilesList({
        projectId: this.project.id,
        page: 1,
        include: ['created_by'],
      })

      this.largeFileWarning = false
      this.incompleteUploadWarning = false
    },

    handleAddFile(error, file) {
      if (isNull(error)) {
        this.incompleteUploadWarning = true

        // If file is bigger than 100MB we will show the warning.
        if (file.file.size > this.maxFileSizeWarning) {
          this.largeFileWarning = true
        }
      }
    },

    handleUpdateFiles(files) {
      if (isEmpty(files)) {
        this.largeFileWarning = false
        this.incompleteUploadWarning = false
      }
    },
  },
}
</script>
