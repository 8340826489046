<template>
  <div
    v-click-outside="closeUploadFilesDropdown"
    class="vd-display-inline-block vd-position-relative"
  >
    <div
      class="vd-margin-left-small vms-project-files__list--item__icon vms-project-files__list--item__icon--medium vd-icon-three-dot"
      @click="toggleUploadFilesDropdown"
    ></div>

    <div
      v-if="showUploadFilesDropdown"
      :class="[
        'vms-project-files__dropdown vd-position-absolute vd-background-white vd-box-shadow vd-background-white vd-border-radius-6',
        isMobileScreen ? 'vms-project-files__dropdown--card' : '',
      ]"
    >
      <a
        v-if="isViewOptionVisible"
        :href="file.url"
        target="_blank"
        rel="noopener"
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--view vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
      >
        View
      </a>
      <div
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--delete vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
        @click="handleDelete"
      >
        Delete
      </div>
      <div
        class="vms-dropdown__list vms-project-files__dropdown-list vms-project-files__action--add-to-brand-hub vd-cursor-pointer vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium vd-black vd-text-align-left"
        @click="handleAddToBrandHub"
      >
        Add to brand hub
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SweetAlert from '@plugins/sweet-alert'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  props: {
    file: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    isViewOptionVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      showUploadFilesDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    ...mapActions({
      deleteProjectFilesAttachment:
        'project/attachment/deleteProjectFilesAttachment',
      addAttachmentToBrandHub: 'project/attachment/addAttachmentToBrandHub',
    }),

    handleDelete() {
      SweetAlert.fire({
        title: 'Warning!',
        html: `<br>Are you sure you want to delete this file? <br><br> <strong>${this.file.name}</strong>`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProjectFilesAttachment({
            project_id: this.project.id,
            attachment_id: this.file.id,
          })
        }
      })
    },

    handleAddToBrandHub() {
      SweetAlert.fire({
        title: 'Warning!',
        html: `<br>Adding file to brand hub will allow all projects under this company to see this file.<br>Are you sure you want to add this file? <br><br> <strong>${this.file.name}</strong>`,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.addAttachmentToBrandHub({
            project_id: this.project.id,
            company_id: this.project.office.company_id,
            attachment_id: this.file.id,
          })
        }
      })
    },

    toggleUploadFilesDropdown() {
      this.showUploadFilesDropdown = !this.showUploadFilesDropdown
    },

    closeUploadFilesDropdown() {
      this.showUploadFilesDropdown = false
    },
  },
}
</script>
